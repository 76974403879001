import {PayloadAction} from '@reduxjs/toolkit';
import {SearchEngine} from '../../app/search-engine/search-engine.js';
import {excerptLengthReducer as excerptLength} from '../../features/excerpt-length/excerpt-length-slice.js';
import {setExcerptLength} from './excerpt-length-actions.js';

/**
 * The excerpt length action creators.
 *
 * @group Actions
 * @category ExcerptLength
 */
export interface ExcerptLengthActionCreators {
  /**
   * Set the length (in number of characters) of the excerpts generated by the indexer based on the keywords present in the query.
   * The index includes the top most interesting sentences (in the order they appear in the item) that fit in the specified number of characters.
   *
   * @param length - The length of the excerpt
   * @returns A dispatchable action.
   */
  setExcerptLength(length: number): PayloadAction<number>;
}

/**
 * Loads the `excerpt length` reducer and returns possible action creators.
 *
 * @param engine - The headless engine.
 * @returns An object holding the action creators.
 *
 * @group Actions
 * @category ExcerptLength
 */
export function loadExcerptLengthActions(
  engine: SearchEngine
): ExcerptLengthActionCreators {
  engine.addReducers({excerptLength});

  return {
    setExcerptLength,
  };
}
